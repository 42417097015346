/* eslint-disable no-unused-vars */
import {getAppTypes}  from "../../services/getAppTypeServices";
const state = {
    groups: null
};

const getters = {
    getAppTypes(state) {
        return state.groups
    }
};
const actions = {
    async getAppTypes({ commit }) {
        let response = await getAppTypes();
        commit('setAppTypes', response.data);
        return response.data;
    }
}
const mutations = {
    setAppTypes(stae, response) {
        stae.groups = response;
    }
}
export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}
import Api from './Api';
const endPoint = 'applications';

const getApps = () => Api.get(`${endPoint}`);

const createApp = (createdApp) => Api.post(`${endPoint}/createApplication`, createdApp);

const getAppById = (appId) => Api.get(`${endPoint}/${appId}/details`);

const updateApp = (application) => Api.put(`${endPoint}/updateApplication`, application);

const getAppsByName = (appname) => Api.get(`${endPoint}/${appname}`);

export {
    getApps,
    createApp,
    getAppById,
    updateApp,
    getAppsByName
}
<template>
  <div class="p-d-flex p-jc-between">
    <div class="arete-logo-container">
      <div class="text-logo">
        <a href="/" class="p-link"> <b>Arete</b> RApps </a>
      </div>
    </div>
    <div class="p-grid">
    </div>

    <div class="p-ml-auto p-d-flex p-mb-2">
      <ProgressSpinner class="p-mt-2 p-mr-1" v-if="isMenuLoading && isSideMenuVisible" style="width:20px;height:20px" strokeWidth="4"  animationDuration=".8s"/>
      <div
        class="profile-avatar p-as-center"
        @click="userProfiletoggle"
        aria-haspopup="true"
        aria-controls="userProfile_menu"
      >
        {{ userInitials }}
      </div>
      <Menu
        id="userProfile_menu"
        ref="menu"
        :model="userMenuItems"
        :popup="true"
        appendTo="body"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: 'TopNavbar',
  data() {
    return {
      showSearchPanel:false,
      userMenuItems: [
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => {
            this.logout();
          },
        },
      ],
      globalSearch: null,
      filteredQuotes: null,
      filterQuote: null,
      searchLoading: false,
      showNoRecords: false,
      filteredDeals: false,
      noQuotes: false,
      noDeals: false,
      quoteText: "",
      dealText: "",
      consumerList: [],
      selectedConsumer:  localStorage.getItem("selectedConsumerMemory"),
      isSideMenuVisible: localStorage.getItem("selectedConsumerMemory") != null ? true : false,
      isMenuLoading: false,
    };
  },
  created(){
    // this.consumerList = JSON.parse(localStorage.consumersMemory);
    // console.log("selected Consumer " +this.consumerList);
  },
  computed: {
    userInitials() {
      return this.$store.getters["login/getUserInitials"];
    },
    isSuperAdmin() {
      return localStorage.getItem('isSuperAdmin');
    },
    isAdmin(){
        return localStorage.getItem('hasAdminRights');
    },
    // consumerList() {
    //   return this.$store.getters['workspaceSync/getConsumers'];
    //   // let result = JSON.parse(localStorage.getItem("consumersMemory"));
    //   // return result;
    // }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    userProfiletoggle(event) {
      this.$refs.menu.toggle(event);
    },
    logout() {
      this.$store.dispatch("login/logout").then(() => {
        this.$router.push({ name: "login" });
      });
    },
    changeConsumer() {
      localStorage.setItem("selectedConsumerMemory", this.selectedConsumer);
     
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#logocontainer {
  display: inline-flex;
  align-items: center;
}

#logocontainer a {
  margin-left: 0.25rem;
}

.arete-logo-container a {
  margin-left: 0.25rem;
}

.layout-topbar .arete-logo-container .text-logo a {
  color: #fff !important;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  padding: 3px;
  text-decoration: none;
}

.layout-topbar .arete-logo-container .text-logo a b {
  font-weight: 600;
}
.custom-Scroll {
    overflow-y: auto;
    max-height: 350px;
    overflow-x: hidden;
    width: 100%;
}
.profile-avatar {
  display: inline-block;
  font-size: 1em;
  width: 2.3em;
  height: 2.3em;
  line-height: 2.3em;
  text-align: center;
  border-radius: 50%;
  background: #8da4bd;
  vertical-align: middle;
  color: white;
  cursor: pointer;
}

.arete-logo-container a {
  margin-left: 0.25rem;
}

.layout-topbar .arete-logo-container .text-logo a {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  padding: 3px;
  text-decoration: none;
}

.layout-topbar .arete-logo-container .text-logo a b {
  font-weight: 600;
}

::v-deep .layout-topbar .layout-topbar-icons button:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  outline-color: transparent !important;
  outline-style: none !important;
}

.search-input {
  // padding: 2px 0 2px 8px;
  //border: 1px solid #384c7f !important;
  height: 32px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: 596px;
  margin: 0 auto;
  display: block;
  background-color: #bbc5d6;
}
.search-input:focus {
    background-color:white;
}
.content-list {
  width: 100%;
  background: #fff;
  float: left;
  // border: 1px solid #e1e1e1;
  border-color: rgba(0, 0, 0, 0.2);
  border-left: 0;
  border-right: 0;
  border-top: 0;
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 8px;
  // display: none
}

.active {
  display: block;
}

.drop-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: white;
}

.drop-list li {
  border: none;
  padding: 0;
  color: white;
}

.drop-list li a {
  width: 100%;
  display: block;
  float: left;
  border-top: 1px dashed rgb(218, 220, 221);
  // background-image: linear-gradient(#ccd1db38, white);
}

.drop-list li a:hover {
  background: #ececec;
  cursor: pointer;
}

.item {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 590px;
}

.text {
  margin: 5px 5px;
  float: left;
  color: #333;
  font-size: 13px;
}
.last-section{
  color:black;font-size:10px;
}
.no-deals{
  color:black;font-size:12px;margin-bottom: 20px;
}
.search-section{
  color:black;font-weight:500
}
.no-quotes{
  color:black;font-size:12px;
}
.tooltipicon{
  color: grey; font-size:12px
}
.p-dropdown {
 // background: #8da4bd !important;
  color: white !important;
}
.p-dropdown-label { 
  color: white !important;

}
.bgcolor{
  background-color: #bbc5d6;
}
// .bgcolor:active{
//   background-color: white;
// }
.p-inputwrapper-focus{
   background-color: white !important;;
}
</style>
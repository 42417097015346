/* eslint-disable no-unused-vars */
import Vue from 'vue';
import 'primevue/resources/themes/nova/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/layout/layout.scss';

//prime vue components
import Ripple from 'primevue/ripple';
import Chips from 'primevue/chips';
import Button from 'primevue/button';
import ToggleButton from 'primevue/togglebutton';
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputSwitch from 'primevue/inputswitch';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import TreeTable from 'primevue/treetable';
import Calendar from 'primevue/calendar';
import Sidebar from 'primevue/sidebar';
import OverlayPanel from 'primevue/overlaypanel';
import RadioButton from 'primevue/radiobutton';
import AutoComplete from 'primevue/autocomplete';
import MultiSelect from 'primevue/multiselect';
import Toolbar from 'primevue/toolbar';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import FileUpload from 'primevue/fileupload';
import Fieldset from 'primevue/fieldset';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Tag from 'primevue/tag';
import Divider from 'primevue/divider';
import Badge from 'primevue/badge';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import PrimeVue from 'primevue/config';
import DataView from 'primevue/dataview';
import Steps from 'primevue/steps';
import Skeleton from 'primevue/skeleton';
import TabMenu from 'primevue/tabmenu';
import CascadeSelect from 'primevue/cascadeselect';
import Rating from 'primevue/rating';
import Timeline from 'primevue/timeline';
import Chip from 'primevue/chip';
import Editor from 'primevue/editor';
import ScrollTop from 'primevue/scrolltop';
import Breadcrumb from 'primevue/breadcrumb';
//import SimpleVueValidation from 'simple-vue-validator';
//const Validator = SimpleVueValidation.Validator;

Vue.directive('tooltip', Tooltip);
Vue.component('Button', Button);
Vue.component('ToggleButton', ToggleButton)
Vue.component('Editor', Editor);
Vue.component('Chip', Chip);
Vue.component('Menu', Menu);
Vue.component('Toast', Toast);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Dialog', Dialog);
Vue.component('InputText', InputText);
Vue.component('Dropdown', Dropdown);
Vue.component('Dialog', Dialog);
Vue.component('Textarea', Textarea);
Vue.component('TreeTable', TreeTable);
Vue.component('Calendar', Calendar);
Vue.component('Sidebar', Sidebar);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('RadioButton', RadioButton);
Vue.component('AutoComplete', AutoComplete);
Vue.component('MultiSelect', MultiSelect);
Vue.component('Toolbar', Toolbar);
Vue.component('Panel', Panel);
Vue.component('Card', Card);
Vue.component('BlockUI', BlockUI)
Vue.component('ProgressSpinner', ProgressSpinner)
Vue.component('Paginator', Paginator)
Vue.component('Message', Message)
Vue.component('Checkbox', Checkbox)
Vue.component('FileUpload', FileUpload)
Vue.component('Fieldset', Fieldset)
Vue.component('Accordion', Accordion)
Vue.component('AccordionTab', AccordionTab)
Vue.component('Tag', Tag)
Vue.component('Divider', Divider)
Vue.component('Badge', Badge)
Vue.component('DataView', DataView)
Vue.component("Steps", Steps)
Vue.component("Skeleton", Skeleton)
Vue.component("TabMenu", TabMenu)
Vue.component("CascadeSelect", CascadeSelect)
Vue.component('Rating', Rating)
Vue.component('Timeline', Timeline)
Vue.component('ConfirmationService', ConfirmationService)
Vue.component('ConfirmPopup', ConfirmPopup)
Vue.component('ConfirmDialog', ConfirmDialog)
Vue.component('Chips', Chips)
Vue.component('ScrollTop', ScrollTop)
Vue.component('Breadcrumb', Breadcrumb)
    //Vue.use(SimpleVueValidation);



Vue.use(PrimeVue);

Vue.use(ToastService);
Vue.use(ConfirmationService);
//Vue.directive('ripple', Ripple);

export default {
    name: "primevue"
};
import Vue from 'vue';
import Vuex from 'vuex';
import LoginModule from './modules/login.module';
import AppModule from './modules/application.module';
import GroupModule from './modules/groups.module';
import StageModule from './modules/stages.module';
import AppTypeModule from './modules/apptypes.module';
import UserModule from './modules/users.module';
import LinksModule from './modules/links.module';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        login: LoginModule,
        app:AppModule,
        group:GroupModule,
        stage:StageModule,
        types:AppTypeModule,
        user:UserModule,
        link:LinksModule
    }
});

import Vue from 'vue';
import { parseISO, format } from 'date-fns';
import App from './App.vue';
import router from './router';
import store from './store/index';
import LoadingBars from './components/LoadingBars';
import LoadingArete from './components/LoadingArete';
import LoginLoadingArete from './components/LoginLoadingArete';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFax, faClipboardCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
library.add(faFax, faClipboardCheck, faCircle);

/* eslint-disable no-unused-vars */
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import PrimeVue from './primevue';
import moment from "moment";
//prime vue components
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Sidebar from 'primevue/sidebar';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import 'primevue/resources/themes/nova/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './assets/layout/layout.scss';

Vue.config.productionTip = false;
Vue.config.devtools = false;

Vue.filter("formatDateTime", value => {
    if (value) {
        let input = format(parseISO(value), 'MM/dd/yyyy hh:mm a') + " UTC";
        var date = new Date(input);
        return date.shift().toLocaleString();
    }
});
Vue.filter("formatDateTimeMT", (value) => {
    if (value) {
        return moment
            .utc(String(value))
            .local()
            .format("DD/MMM/YY hh:mm A");
    }
});
Vue.filter("count", array => {
    return array.length;
})
Vue.filter("FormatDateTime", value => {
    if (value) {
        var date = new Date(value);
        return date.toLocaleDateString();
    }
});

Vue.mixin({
    methods: {
        FormatDateTime(value) {
            if (value) {
                var date = new Date(value);
                return date.toLocaleDateString();
            }
        },
        ToArryLength(selected) {
            return selected != null ? selected.length : 0;
        },
        ToList(selectedlist) {
            return selectedlist.join(', ');
        },
        getwebURL(environment) {
            let env = environment;
            let url = null;
            let environments = env.environments ? env.environments : null;
            if (environments != null) {
                environments.forEach((element) => {
                    if (element["name"].toLowerCase().includes('pro')) {
                        url = element.url;
                    }
                })
                return url;
            }
        },
        getqaURL(environment) {
            let env = environment;
            let url = null;
            let environments = env.environments ? env.environments : null;
            if (environments != null) {
                environments.forEach((element) => {
                    if (element["name"].toLowerCase().includes('qa')) {
                        url = element.url;
                    }
                })
                return url;
            }

        },
        dummyData() {
            return [{}, {}, {}, {}, {}, {}, {}]
        },
        convertIsActive(status) {
            return status == "Yes" ? true : false;
        },
        convertNumberFormat(value) {
            if (value) {
                value = value.toString();
                var lastThree = value.substring(value.length - 3);
                var otherNumbers = value.substring(0, value.length - 3);
                if (otherNumbers != '')
                    lastThree = ',' + lastThree;
                var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
                return res;
            }
        },
        getuserName(user) {
            return user ? typeof(user) == 'object' ? user.displayName ? user.displayName : "No Owner" : user : "No Owner";
        },
        getuserEmail(user) {
            return user ? typeof(user) == 'object' ? user.email ? user.email : "No Owner" : user : "No Owner";
        },
    }
})
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(ToastService);
Vue.use(VueSidebarMenu);
Vue.directive('tooltip', Tooltip);
Vue.component('Loading', LoadingBars);
Vue.component('LoadingArete', LoadingArete);
Vue.component('Button', Button);
Vue.component('Menu', Menu);
Vue.component('Toast', Toast);
Vue.component('DataTable', DataTable);
Vue.component('Column', Column);
Vue.component('ColumnGroup', ColumnGroup);
Vue.component('Sidebar', Sidebar);
Vue.component('primevue', PrimeVue);
/* eslint-disable no-unused-vars */
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.component('LoginLoadingArete', LoginLoadingArete);
Vue.component('Dropdown', Dropdown)
Vue.component('Calendar', Calendar)
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
import * as msal from '@azure/msal-browser'
import { isUserSuperAdmin, isUserAdmin } from '../../services/userService';
const state = {
    user: {},
    isUserAdmin: null,
    isUserSuperdmin: null,
}

const getters = {
    getToken(state) {
        return state.user.token;
    },
    getUser(state) {
        return state.user;
    },
    getUserAdmin(state) {
        return state.isUserAdmin;
    },
    getUserSuperAdmin(state) {
        return state.isUserAdmin;
    },
    getUsername(state) {
        if (state.user.name && state.user.name.trim())
            return state.user.name;
        return state.user.email
    },
    getIsUserAuthenticated(state) {
        return state.user && !!state.user.token
    },
    getUserInitials: state => {
        let name = '';
        if (state.user.name && state.user.name.trim()) {
            name = state.user.name
        } else if (state.user.email) {
            name = state.user.email
        }
        var names = name.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    },
}

const msalConfig = {
    auth: {
        clientId: process.env.VUE_APP_clientId,
        authority: process.env.VUE_APP_tenant,
        redirectUri: process.env.VUE_APP_redirectUri,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
}

const msalInstance = new msal.PublicClientApplication(msalConfig);

const updateStore = (commit, response) => {
    const userDetails = {
        id: response.uniqueId,
        token: response.accessToken,
        name: response.account.name,
        email: response.account.userName,
        isUserAdmin: response.isUserAdmin,
        isUserSuperAdmin: response.isUserSuperAdmin,
    };
    //console.log(response);
    commit('setUser', userDetails);
}

const loginRequest = {
    scopes: process.env.VUE_APP_scopes.split(',')
};

const actions = {
    async login({ commit }) {
        try {
            const tokenResponse = await msalInstance.loginPopup(loginRequest);
            const userId = tokenResponse.account.localAccountId;
            isUserAdmin(userId).then((response) => {
                tokenResponse.isUserAdmin = response.data.data;
                updateStore(commit, tokenResponse);
                commit('setAdmin', response.data.data);
                localStorage.setItem('isUserAdmin', response.data.data);
            });
            isUserSuperAdmin(userId).then((response) => {
                tokenResponse.isUserSuperAdmin = response.data.data;
                updateStore(commit, tokenResponse);
                commit('setSuperAdmin', response.data.data);
                localStorage.setItem('isUserSuperAdmin', response.data.data);
            });
            updateStore(commit, tokenResponse);
        } catch (error) {
            console.log(error);
        }
    },
    logout({ commit }) {
        return new Promise(resolve => {
            sessionStorage.clear();
            // localStorage.removeItem('isUserAdmin');
            // localStorage.removeItem('isUserSuperAdmin');
            localStorage.removeItem("consumersMemory");
            commit('logout');
            resolve();
        })
    },
    async acquireTokenSilent({ commit, dispatch }) {
        try {
            localStorage.removeItem("consumersMemory");
            const silentRequest = {
                scopes: process.env.VUE_APP_scopes.split(','),
                account: msalInstance.getAllAccounts()[0]
            }
            const tokenResponse = await msalInstance.acquireTokenSilent(silentRequest);
            const userId = tokenResponse.uniqueId;
            isUserAdmin(userId).then((response) => {
                tokenResponse.isUserAdmin = response.data.data;
                updateStore(commit, tokenResponse);
                commit('setAdmin', response.data.data);
                //localStorage.removeItem("isUserAdmin");
                localStorage.setItem('isUserAdmin', response.data.data);
            });
            isUserSuperAdmin(userId).then((response) => {
                tokenResponse.isUserSuperAdmin = response.data.data;
                updateStore(commit, tokenResponse);
                commit('setSuperAdmin', response.data.data);
                //localStorage.removeItem('isUserSuperAdmin');
                localStorage.setItem('isUserSuperAdmin', response.data.data);
            });
            updateStore(commit, tokenResponse);
        } catch (error) {
            if (error.name === 'InteractionRequiredAuthError') {
                dispatch('login');
            } else {
                console.log(error);
            }
        }
    }
}

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.user.token = token;
    },
    logout(state) {
        state.user = {};
    },
    setAdmin(state, response) {
        state.isUserAdmin = response;
    },
    setSuperAdmin(state, response) {
        state.isUserSuperAdmin = response;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
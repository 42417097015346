/* eslint-disable no-unused-vars */
import {
    getUsers,
    getUsersWithname,
    saveAppAdmins,
    updateAdminUser,
    removeAdminUser,
    getAppAdminUsers
} from "../../services/userService";
const state = {
    users: null,
    appAdmins: null,
};

const getters = {
    getUsers(state) {
        return state.users
    },
    getUsersWithname(state) {
        return state.users
    },
    getAppAdmins(state) {
        return state.appAdmins
    },
};
const actions = {
    async getUsers({ commit }, filter) {
        let response = await getUsers(filter);
        commit('setUsers', response.data);
        return response.data;
    },
    async getUsersWithname({ commit }, filter) {
        let response = await getUsersWithname(filter);
        commit('setUsersWithname', response.data);
        return response.data;
    },
    async saveappAdmins({ commit }, admins) {
        let response = await saveAppAdmins(admins);
        return response.data
    },
    async getAppAdminUsers({ commit }, filter) {
        let response = await getAppAdminUsers(filter);
        commit('setAppAdmins', response.data);
        return response.data;
    },
    async updateAdminUser({ commit }, user) {
        let response = await updateAdminUser(user);
        commit('setAppAdmins', response.data);
        return response.data;
    },
    async removeAdminUser({ commit }, user) {
        let response = await removeAdminUser(user);
        commit('setAppAdmins', response.data);
        return response.data;
    },
}
const mutations = {
    setUsers(stae, response) {
        stae.users = response;
    },
    setUsersWithname(stae, response) {
        stae.users = response;
    },
    setAppAdmins(state, response) {
        state.appAdmins = response;
    }
}
export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}
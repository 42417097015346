import Api from './Api';
const endPoint = 'users';

const getUsers = (user) => Api.get(`${endPoint}/${user}`);
const getUsersWithname = (user) => Api.get(`${endPoint}/searchUser/${user}`);
const saveAppAdmins = (admins) => Api.post(`${endPoint}/saveAdmin`, admins);
const getAppAdminUsers = () => Api.get(`${endPoint}/getAdminUsers`);
const updateAdminUser = (user) => Api.post(`${endPoint}/updateAdmin`, user);
const removeAdminUser = (user) => Api.post(`${endPoint}/removeAdmin`, user);
const isUserAdmin = userId => Api.get(`${endPoint}/${userId}/isuserAdmin`);
const isUserSuperAdmin = userId => Api.get(`${endPoint}/${userId}/isuserSuperAdmin`);

export {
    getUsers,
    getUsersWithname,
    saveAppAdmins,
    getAppAdminUsers,
    updateAdminUser,
    removeAdminUser,
    isUserAdmin,
    isUserSuperAdmin
}
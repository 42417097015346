/* eslint-disable no-unused-vars */
import {
  getApps,
  createApp,
  getAppById,
  updateApp,
  getAppsByName,
} from '../../services/applicationService';
const state = {
  apps: null,
};
const getters = {
  getApps(state) {
    return state.apps;
  },
  groupAppStagesFromData(state) {
    let groupedstates = state.apps.reduce((acc, app) => {
      if (acc.indexOf(app.stage) < 0 && app.stage) {
        acc.push(app.stage);
        return acc;
      } else {
        return acc;
      }
    }, []);
    return groupedstates;
  },
};
const actions = {
  async getApps({ commit }) {
    let response = await getApps();
    commit('setApps', response.data.data);
    return response.data;
  },
  async getAppsByName({ commit }, appname) {
    let response = await getAppsByName(appname);
    commit('setApps', response.data);
    return response.data;
  },
  async getAppById({ commit }, appId) {
    let response = await getAppById(appId);
    return response.data;
  },
  async createApplication({ commit }, application) {
    let response = await createApp(application);
    return response.data;
  },
  async updateApp({ commit }, createApp) {
    let response = await updateApp(createApp);
    return response.data;
  },
};
const mutations = {
  setApps(stae, response) {
    stae.apps = response;
  },
};
export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};

<template>
  <div class="layout-wrapper layout-overlay">
    <TopNavBar :class="topbarClass" />
    <div
      id="quoteApp"
      :class="[{ collapsed: collapsed }, { onmobile: isOnMobile }]"
    >
      <div class="">
        <div class="layout-main">
          <router-view />
        </div>
        <AppFooter />
        <sidebar-menu
          :menu="menu"
          :collapsed="collapsed"
          :theme="selectedTheme"
          :show-one-child="true"
          @toggle-collapse="onToggleCollapse"
          @item-click="onItemClick"
          :style="[{ 'max-width': sidebarWidth }]"
          @mouseenter="onMouseEnter"
        />
        <div
          v-if="isOnMobile && !collapsed"
          class="sidebar-overlay"
          @click="collapsed = true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import TopNavBar from "../layout/TopNavbar.vue";
import AppFooter from "@/components/layout/AppFooter.vue";

export default {
  name: "Default",
  created() {
    this.isSuperAdmin = localStorage.getItem("isUserSuperAdmin");
    this.isAdmin = localStorage.getItem("isUserAdmin");
  },
  props: {
    widthCollapsed: {
      type: String,
      default: "50px",
    },
    width: {
      type: String,
      default: "250px",
    },
  },
  methods: {
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },

    //  New SideBar methods starts here
    onMouseEnter() {
      if (this.isCollapsed) {
        if (this.mobileItemTimeout) clearTimeout(this.mobileItemTimeout);
        this.sidebarWidth = "250px";
      }
    },
    onToggleCollapse(collapsed) {
      // console.log(collapsed);
      this.collapsed = collapsed;
      localStorage.setItem('isCollapsed', collapsed);
    },
    onItemClick(event, item, node) {
      // console.log("Router full path : " + this.$route.fullPath);
      // console.log(item);
      var isRouteFromQuoteCreate = this.$route.fullPath.includes(
        "quotes/create"
      );
      //console.log(isRouteFromQuoteCreate);
      if (isRouteFromQuoteCreate) {
        this.$router.go();
      }
      // console.log("onItemClick");
      console.log(event);
      console.log(item);
      console.log(node);
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = localStorage.getItem("isCollapsed") == "true" ? true : false;
      }
    },
  },
  computed: {
    sidebarWidth() {
      return this.collapsed ? this.widthCollapsed : this.width;
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
    topbarClass() {
      return [
        "layout-topbar",
        {
          "layout-topbar-dark": this.layoutColorMode === "dark",
        },
      ];
    },
    isUserAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserAdmin || false;
    },
    isUserSuperAdmin() {
      let loggedUser = this.$store.getters["login/getUser"];
        return loggedUser.isUserSuperAdmin || false;
    }
  },
  data() {
    return {
      layoutMode: "static",
      layoutColorMode: "light",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      isSuperAdmin: false,
      isAdmin: localStorage.getItem("isUserAdmin"),
      collapsed: localStorage.getItem("isCollapsed") == "true" ? true : false,
      isCollapsed: this.collapsed,
      menu: [
        {
          href: "/apps",
          title: "Home",
          icon: "fa fa-home",
          child: [
            {
              href: "/createapp",
              title: "Create Application",
              icon: "fa fa-plus",
              hidden: (localStorage.getItem("isUserAdmin")=='true'?false:true),
            },
            {
              href: "/apps",
              title: "View Applications",
              icon: "fa fa-eye",
            }
          ],
        },
        // {
        //   href: "/settings/general",
        //   title: "Configuration",
        //   icon: "fa fa-cogs",
        //   child: [
        //     {
        //       href: "/applicationtypes",
        //       title: "Application Types",
        //       icon: "fa fa-cog",
        //     },
        //     {
        //       href: "/applicationgroups",
        //       title: "Application Groups",
        //       icon: "fas fa-flag",
        //     },
        //     {
        //       href: "/applicationlinks",
        //       title: "Application Links",
        //       icon: "fas fa-flag",
        //     },
        //     {
        //       href: "/applicationstages",
        //       title: "Application Stages",
        //       icon: "fas fa-flag",
        //     },
        //   ],
        // },
        {
          title: "Settings",
          icon: "fa fa-cogs",
          hidden: (localStorage.getItem("isUserSuperAdmin")=='true'?false:true),
          child: [
            {
              href: "/usermanagment",
              title: "App Owners",
              icon: "fa fa-user-shield",
            }
            // {
            //   href: "/cachemanagment",
            //   title: "Cache Managment",
            //   icon: "fas fa-flag",
            // },
          ],
        },         
      ],
      themes: [
        {
          name: "white theme",
          input: "",
        },
        {
          name: "White theme",
          input: "white-theme",
        },
      ],
      selectedTheme: "white-theme",
      isOnMobile: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    TopNavBar: TopNavBar,
    AppFooter: AppFooter,
  },
  mounted() {
    this.$root.$on("defaultComp", () => {
      this.reload();
    });
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss">
#quoteApp {
  padding-left: 250px;
  transition: 0.3s ease;
}
#quoteApp.collapsed {
  padding-left: 50px;
}
#quoteApp.onmobile {
  padding-left: 50px;
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}
.v-sidebar-menu {
  top: 50px !important;
}
.v-sidebar-menu .vsm--mobile-item {
  max-width: 250px !important;
}
.v-sidebar-menu .vsm--link {
  font-size: 14px !important;
}
.vsm--arrow{
 color: #262626; 
}
.v-sidebar-menu .vsm--icon {
    display: inline-block;
    margin-right: 10px;
    background: #225083;
    height: 30px;
    line-height: 30px;
    width: 30px;
    flex-shrink: 0;
    text-align: center;
    border-radius: 3px;
}
.vsm_collapsed .vsm--title{
  color:black !important
}
</style>

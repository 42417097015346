/* eslint-disable no-unused-vars */
import {getLinks}  from "../../services/linkService";
const state = {
    links: null
};

const getters = {
    getLinks(state) {
        return state.links
    }
};
const actions = {
    async getLinks({ commit }) {
        let response = await getLinks();
        commit('setLinks', response.data);
        return response.data;
    }
}

const mutations = {
    setLinks(stae, response) {
        stae.links = response;
    }
}
export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}
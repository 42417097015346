import Vue from 'vue';
import Router from 'vue-router';
import LoginComponent from './components/Login.vue';
// import DefaultComponent from './components/Default.vue';
import store from './store/index';
import DefaultComponent from './components/layout/AppLayout.vue';

Vue.use(Router);

//function checkAuthorization(to, from, next) {
//    const requiredRole = to.meta.requiredRole;
//    if (to.meta && to.meta.requiredRole) {
//        const roles = store.getters['login/getUserRoles'];
//        //check if the user has required role
//        let userRoles = roles.filter(r => r == requiredRole);
//        if (userRoles.length == 1) {
//            //if user has the required role
//            next()
//        } else {
//            //if user doesn't have the required role then
//            //navigate to home page
//            next('errorpage');
//        }
//    } else {
//        next();
//    }
//}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/login',
            name: 'login',
            component: LoginComponent,
        },
        {
            path: '/',
            component: DefaultComponent,
            children: [{
                    name: 'dashboard',
                    path: '',
                    component: () =>
                        import ('./views/Application/Apps.vue'),
                    //beforeEnter: checkAuthorization,
                    //meta: {
                    //    requiredRole: 'GLOBALADMIN'
                    //},
                },
                {
                    name: 'apps',
                    path: '/apps',
                    component: () =>
                        import ('./views/Application/Apps.vue'),
                },
                {
                    path: "/app/:appid/details",
                    name: "app-details-view",
                    component: () =>
                        import ('./views/Application/AppDetails.vue'),
                },
                {
                    name: 'createapp',
                    path: '/createapp',
                    component: () =>
                        import ('./views/Application/CreateApp.vue'),
                },
                {
                    path: "/app/:appid/edit",
                    name: "app-edit-view",
                    component: () =>
                        import ('./views/Application/AppEdit.vue'),
                },
                {
                    name: 'applicationgroups',
                    path: '/applicationgroups',
                    component: () =>
                        import ('./views/Configuration/Groups.vue'),
                },
                {
                    name: 'applicationlinks',
                    path: '/applicationlinks',
                    component: () =>
                        import ('./views/Configuration/Links.vue'),
                },
                {
                    name: 'applicationstages',
                    path: '/applicationstages',
                    component: () =>
                        import ('./views/Configuration/Stages.vue'),
                },
                {
                    name: 'applicationtypes',
                    path: '/applicationtypes',
                    component: () =>
                        import ('./views/Configuration/Types.vue'),
                },
                {
                    name: 'usermanagment',
                    path: '/usermanagment',
                    component: () =>
                        import ('./views/Settings/UserManagment.vue'),
                },
                {
                    name: 'cachemanagment',
                    path: '/cachemanagment',
                    component: () =>
                        import ('./views/Settings/CacheManagment.vue'),
                },
                {
                    path: '*',
                    component: () =>
                        import ('./views/404.vue')
                }
            ]
        }
    ]
});

router.beforeEach(async(to, from, next) => {
    await store.dispatch('login/acquireTokenSilent');
    const isAuthenticated = store.getters['login/getIsUserAuthenticated'];
    if (to.name !== 'login' && !isAuthenticated) {
        next('/login')
    } else {
        next();
    }
});

export default router;
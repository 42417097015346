// Customizing the axios api calls
import axios from 'axios';
import store from '../store/index';

const Api = axios.create({
    baseURL: process.env.VUE_APP_baseUrl,
    //timeout:5000, //5 seconds
});

Api.interceptors.request.use(
    function (config) {
        const token = store.getters['login/getToken'];
        //for every request add the token to the authorization header
        config.headers.Authorization = `${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    function (response) {
        return response;
    },
   async function (error) {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401) {
            const token = await store.dispatch('login/acquireTokenSilent');
            if (token) {
                originalRequest.headers.Authorization = token;
                await Api(originalRequest);
            } else {
                store.dispatch('login/logout');
            }
        }
        return Promise.reject(error);
    }
);

export default Api;

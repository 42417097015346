/* eslint-disable no-unused-vars */
import {getGroups}  from "../../services/groupServices";
const state = {
    groups: null
};

const getters = {
    getGroups(state) {
        return state.groups
    }
};
const actions = {
    async getGroups({ commit }) {
        let response = await getGroups();
        let groups = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        commit('setGroups', groups);
        return response.data;
    }
}
const mutations = {
    setGroups(stae, response) {
        stae.groups = response;
    }
}
export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}
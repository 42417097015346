/* eslint-disable no-unused-vars */
import {getStages}  from "../../services/stageService";
const state = {
    stages: null
};

const getters = {
    getStages(state) {
        return state.stages
    }
};
const actions = {
    async getStages({ commit }) {
        let response = await getStages();
        let stages = response.data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        commit('setStages', stages);
        return response.data;
    }
}

const mutations = {
    setStages(stae, response) {
        stae.stages = response;
    }
}
export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}